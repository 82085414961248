import { createApp } from 'vue'
import router from './router'
import App from './App.vue'
import './main.css'
//引入样式
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
//引入icon文件
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
// 导入Animate.css
import 'animate.css';
// 引入pinia
import { createPinia } from 'pinia'
//
import VueAwesomeSwiper from 'vue-awesome-swiper'
import SwiperClass from 'swiper'

SwiperClass.use([])
import 'swiper/css'



const app = createApp(App);
const pinia = createPinia();
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
  }
  
app.use(pinia);
app.use(router);
app.use(ElementPlus);
app.use(VueAwesomeSwiper);
app.mount('#app')
