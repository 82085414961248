import { createRouter,createWebHistory } from "vue-router";

const routes = [
    {
        path:'/',
        name:'home',
        component:()=>import ('@/views/homePage/homePage.vue')
    },
    {
        path:'/intoRuixin',
        name:'intoRuixin',
        component:()=>import ('@/views/intoRuixin/intoRuixin.vue')
    },
    {
        path:'/news',
        name:'news',
        component:()=>import ('@/views/newsMsg/newsMsg.vue')
    },
    {
        path:'/contactUs',
        name:'contactUs',
        component:()=>import ('@/views/contactUs/contactUs.vue')
    },
    {
        path:'/joinUs',
        name:'joinUs',
        component:()=>import ('@/views/joinUs/compusRecuit.vue'),
    },
    // {
    //     path: '/joinUs/compus',
    //     name: 'compus',
    //     component: () => import('@/views/joinUs/compusRecuit.vue')
    //   },
    {
        path:'/businessField',
        name:'businessField',
        component:()=>import ('@/views/business/bussinessFiled.vue')
       
    }
   

    

]
const router = createRouter({
    history:createWebHistory(),
    routes
})
export default router